nav {
    margin: 0;
  }

.navClass {
    background-color: #EE5A24;
}
.navHeader {
    color: #f5f6fa;
}

.trans {
    -webkit-transition: background 0.4s, border-radius 0.4s;
    transition: background 0.4s, border-radius 0.4s;
}